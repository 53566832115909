import React, { useEffect, useState } from 'react';
import {
  Route, Link, useHistory, useLocation, Switch,
} from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import moment from 'moment';
import 'moment/locale/en-ca';
import 'moment/locale/ru';
import HelpSuccess from 'components/Help/HelpSuccess';
import Sidebar from './Sidebar';
import Header from './Header';
import FirstMonitoring from '../Monitorings/First';
import Button from '../Button';
import Monitor from '../Monitor';
import MonitorsStore from '../../mobx/monitors';
import ProjectsStore from '../../mobx/projects';
import SelectMonitoring from '../Monitorings/Select';
import Tabs from '../Tabs';
import Alert from '../Alert';
import SimpleMonitoring from '../Monitorings/Simple';
import MonitorPage from '../Monitor/MonitorPage';
import Notification from '../Notification/Notification';
import Profile from '../Profile/Profile';
import ApiClient from '../../core/apiClient';
import Loader from '../Loader';
import AgentMonitor from '../Monitorings/Agent';
import ProfilePassword from '../Profile/Password';
import PublicOffer from '../PageText/PublicOffer';
import Terms from '../PageText/Terms';
import Help from '../Help/Help';
import InviteProject from '../Invite/InviteProject';
import { IInvites } from '../../interfaces/invites';
import MeStore from '../../mobx/me';
import MenuFixed from './MenuFixed';
import StatusPage from '../Status/StatusPage';
import { handelOutsideNotification } from '../../scripts/outsideClickElement';
import declOfNum from '../../scripts/declOfNum';
import EditMonitoring from '../Monitorings/Edit';

const AppContainer = () => {
  const { t } = useTranslation();
  const history = useHistory();

  moment.locale(i18n.language);

  const pathProfile = history.location.pathname.split('/');
  const blockWidth: number = 368;
  const blockHeight: number = 242;
  const countWidth: number = Math.floor(window.innerWidth / blockWidth);
  const countHeight: number = Math.floor(window.innerHeight / blockHeight);
  const limit: number = countWidth * countHeight;

  useEffect(() => {
    MonitorsStore.limit = limit;
    MonitorsStore.getListFirstMonitors();
    MeStore.getMe();
  }, []);

  const Monitors = observer(() => {
    const projectMonitors = MonitorsStore.monitors.filter((m) => ProjectsStore.projectTab === 0 || m?.project?.id === ProjectsStore.projectTab);
    const wrongMonitors = projectMonitors.filter((m) => !!m.state && m?.state?.state === 'error');
    const goodMonitors = projectMonitors.filter((m) => m.state === undefined || m.state === null || (m.state && m?.state?.state !== 'error'));

    useEffect(() => {
      const path = history.location.pathname;
      if (path.includes('project/')) {
        MonitorsStore.sortMonitorsByProjects(Number(path.replace(/^\D+/g, '')));
      }
    }, [ProjectsStore.projects]);

    useEffect(() => {
      if (ProjectsStore.projectTab !== 0) {
        MonitorsStore.getMonitorsByProject(ProjectsStore.projectTab);
      }
    }, [ProjectsStore.projectTab]);

    const [invite, setInvite] = useState<IInvites[] | undefined>();

    useEffect(() => {
      setInvite(MeStore?.me?.invites);
    }, [MeStore?.me?.invites]);

    const declinationKeyTranslate = (item: number) => declOfNum(item, ['monitorKeyOne', 'monitorKeyTwo', 'monitorKeyThree']);

    return (
      <div className="monitors">
        {invite?.[0] && <InviteProject />}
        {MeStore?.me?.status === 1 && (<Alert type="warning" title={t('Синхронизация приостановлена')} description="У вас не хватает средств на балансе. Чтобы восстановить синхронизацию, пополните баланс." />)}
        {wrongMonitors.length > 0 && MeStore?.me?.status !== 1 && <Alert type="warning" title={t('Something is wrong')} description={`${wrongMonitors.length} ${t(declinationKeyTranslate(wrongMonitors.length))}`} />}
        {wrongMonitors.map((monitor) => (
          <Monitor
            // @ts-ignore
            key={`monitor-${monitor.id}`}
            url={monitor.url}
            status={monitor.status}
            name={monitor.name}
            project={monitor.project}
            project_id={monitor.project_id}
            type={monitor.type}
            id={monitor.id}
            user_id={monitor.user_id}
            state={monitor.state}
            daily={monitor.daily}
          />
        ))}
        {goodMonitors.length > 0 && wrongMonitors.length > 0 && <Alert type="good" title={t('Others are good')} />}
        {goodMonitors.map((monitor) => (
          <Monitor
            // @ts-ignore
            key={`monitor-${monitor.id}`}
            url={monitor.url}
            status={monitor.status}
            name={monitor.name}
            project={monitor.project}
            project_id={monitor.project_id}
            type={monitor.type}
            id={monitor.id}
            user_id={monitor.user_id}
            state={monitor.state}
            daily={monitor.daily}
          />
        ))}
        {MonitorsStore.loading && <Loader />}
      </div>
    );
  });

  const ProjectTabName = observer(() => (
    <div>{ProjectsStore.projectTabName}</div>
  ));

  const handleScroll = () => {
    const doc = document.documentElement;
    if (ProjectsStore.projectTab === 0) {
      if (doc.scrollTop + doc.clientHeight > doc.scrollHeight - 150) {
        window.removeEventListener('scroll', handleScroll);
        if (MonitorsStore.totalCount > MonitorsStore.monitors.length) {
          MonitorsStore.offset += MonitorsStore.limit;
          MonitorsStore.setLoading(true);
          ApiClient.getListMonitors(MonitorsStore.limit, MonitorsStore.offset).then((res) => {
            MonitorsStore.reachMonitors(res?.data.monitors || []);
            window.addEventListener('scroll', handleScroll);
            MonitorsStore.setLoading(false);
          });
        }
      }
    }
  };

  const location = useLocation();

  useEffect(() => {
    const path = location.pathname;

    if (path === '/' || path.includes('project/')) window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [location]);

  return (
    <Switch>
      <Route path="/status" exact>
        <StatusPage />
      </Route>
      <>
        <MenuFixed />
        <div className="app-container" onClick={handelOutsideNotification}>
          <Sidebar />
          <Header />
          <div className={`container ${pathProfile.map((item) => (item === 'profile' ? ' container--profile ' : ''))}`}>
            <div className="inner">
              <Route exact path="/">
                <div className="top-line">
                  <div className="t-headline-2">{t('Monitoring')}</div>
                  <div className="new-monitoring">
                    <Link to="/select-monitoring">
                      <Button text={t('New monitoring')} iconLeft="plus-circle" bc="indigo" size="big" />
                    </Link>
                  </div>
                </div>
                <Tabs />
                <Monitors />
              </Route>
              <Route exact path="/project/:id">
                <div className="top-line">
                  <div className="t-headline-2">
                    <ProjectTabName />
                  </div>
                  <div className="new-monitoring">
                    <Link to="/select-monitoring">
                      <Button text={t('New monitoring')} iconLeft="plus-circle" bc="indigo" size="big" />
                    </Link>
                  </div>
                </div>
                <Monitors />
              </Route>
              <Route exact path="/monitor/:id">
                <MonitorPage />
              </Route>
              <Route exact path="/edit-monitoring/:id">
                <EditMonitoring />
              </Route>
              <Route exact path="/select-monitoring">
                <SelectMonitoring />
              </Route>
              <Route exact path="/first-monitoring">
                <FirstMonitoring />
              </Route>
              <Route exact path="/monitoring/create/:typeMonitor">
                <SimpleMonitoring />
              </Route>
              <Route exact path="/monitoring-agent">
                <AgentMonitor />
              </Route>
              <Route
                path={['/profile',
                  '/profile/menu',
                  '/profile/account',
                  '/profile/monitorings',
                  '/profile/subscriptions',
                  '/profile/billing/success',
                  '/profile/billing/failed',
                  '/profile/billing',
                  '/profile/notifications',
                  '/profile/messaging',
                  '/profile/messaging/telegram']}
                exact
              >
                <Profile />
              </Route>
              <Route exact path="/profile/password">
                <ProfilePassword />
              </Route>
              <Route path="/public-offer" exact>
                <PublicOffer />
              </Route>
              <Route path="/terms" exact>
                <Terms />
              </Route>
              <Route path="/help/success" exact>
                <HelpSuccess />
              </Route>
              <Route exact path="/help">
                <Help />
              </Route>
            </div>
            <Notification />
          </div>
        </div>
      </>
    </Switch>
  );
};

export default AppContainer;
